<style lang="scss">
@import './login.scss';
</style>

<template>
  <div class="forget-password" :style="{backgroundImage:info.background_img ? 'url(' + info.background_img + ')' : 'url('+backgroundImage+')'}" @keydown.enter="handleSubmit">
    <div class="login-mask">
      <div class="login-logo">
        <img v-if="info.logo" :src="info.logo" height="42" />
        <img v-else src="../../assets/images/new_icon/logo_w_icon.png" style="width: 158px" alt="">
        <p>· {{ info.full_name || defaultInfo.name }}</p>
      </div>
      <div class="forget-password-con">
        <Row>
          <i-col span="18" push="3">
            <div class="form-con">
              <p class="form-title-style">{{$t('forgetPassword_forgot_password')}}</p>

              <Form ref="forgetPasswordForm" :model="form" :rules="rules">
                <FormItem prop="mobile">
                  <i-input v-model="form.mobile" size="large" class="borerB" clearable :placeholder="$t('register_input_mobile')">
                    <!--                                  <span slot="prefix" style="height:100%;display:flex;align-items:center;justify-content:center">-->
                    <!--                                        <i class="el-icon-phone"></i>-->
                    <!--                                    </span>-->
                  </i-input>
                </FormItem>
                <FormItem prop="newpassword">
                  <i-input v-model="form.newpassword" type="password" class="borerB" clearable :maxlength="32" size="large" :placeholder="$t('forgetPassword_new_password')">
                    <!--                                  <span slot="prefix">-->
                    <!--                                      <Icon type="md-key" />-->
                    <!--                                  </span>-->
                  </i-input>
                  <input type="password" hidden autocomplete="new-password" value="" />
                </FormItem>
                <FormItem prop="newpassword">
                  <i-input v-model="form.againnewpassword" type="password" class="borerB" clearable :maxlength="32" size="large" placeholder="请再次输入你的密码">
                    <!--                                  <span slot="prefix">-->
                    <!--                                      <Icon type="md-key" />-->
                    <!--                                  </span>-->
                  </i-input>
                  <input type="password" hidden autocomplete="new-password" value="" />
                </FormItem>
                <FormItem prop="captcha">
                  <Row>
                    <i-col span="18">
                      <i-input clearable v-model="form.captcha" class="borerB" clearable :maxlength="4" size="large"
                               :placeholder="$t('register_input_captcha')">
                        <!--              <span slot="prefix">-->
                        <!--                  <Icon :size="18" type="ios-copy-outline"></Icon>-->
                        <!--                </span>-->
                      </i-input>
                    </i-col>
                    <i-col span="5" push="1">
                      <div @click="getCaptcha" style="height: 36px;text-align:center;border-radius: 4px;cursor:pointer;;">
                        <img :src="captchaUrl" alt="" style="display:inline-block;height: 36px">
                      </div>
                    </i-col>
                  </Row>
                </FormItem>
                <FormItem prop="mobileCode">
                  <Row>
                    <i-col span="18">
                      <i-input clearable v-model="form.mobileCode" class="borerB" clearable :maxlength="4" size="large"
                               :placeholder="$t('register_input_mobile_code')">
                        <!--                <span slot="prefix">-->
                        <!--                    <i class="el-icon-lock"></i>-->
                        <!--                </span>-->
                      </i-input>
                    </i-col>
                    <i-col span="5" push="1">
                      <div class="get-code" @click="getMobileCode" v-if="mobileCodeStatus">{{ $t('login_get_code') }}</div>
                      <div class="get-code-not" v-else>{{sec}}{{$t('login_mobild_code_unit')}}{{ $t('login_mobild_code_again') }}</div>
                    </i-col>
                  </Row>
                </FormItem>


                <div style="margin-bottom: 10px; margin-top: 30px">
                  <Button @click="handleSubmit" type="primary" :loading="loading" style="height:50px;" size="large" long>{{$t('forgetPassword_submit')}}</Button>
                </div>
                <FormItem prop="read" class="read-tip">
                  <Row type="flex" justify="space-between">
                    <Col>
                      <span style="color:#2d8cf0;cursor:pointer" @click="toLogin">{{$t('forgetPassword_to_login')}}</span>
                    </Col>
                    <!--                  <Col>-->
                    <!--                    {{$t('login_account_no')}}<span style="color: #2d8cf0;cursor: pointer" @click="toRegisterPage">{{$t('login_register')}}</span>-->
                    <!--                  </Col>-->
                  </Row>
                </FormItem>
              </Form>
            </div>
          </i-col>
        </Row>
        <div style="width:100%;">
          <p style="width:100%;text-align:center;margin-bottom:25px;color:#7a90a7">{{$t('common_copyright')}}</p>
          <div class="use-chrome" v-if="userAgent.indexOf('Chrome') < 0">
            <!-- {{$t('common_use_chrome') }} -->
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Cookies from "js-cookie";
import util from "@/utils/tools.js";

export default {
  data() {

    const validateMobile = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('register_input_mobile_empty')));
      }
      if (value.length > 11) {
        return callback(new Error(this.$t('register_input_mobile_limit_max')));
      }
      if (value.length < 11) {
        return callback(new Error(this.$t('register_input_mobile_limit_min')));
      }
      callback();
    };
    const validateNewPassword = (rule, value, callback) => {
      var re = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,32}$/;
      if (!value) {
        callback(new Error(this.$t("forgetPassword_newpassword_format_error")));
      }else if (!re.test(value)) {
        callback(new Error(this.$t("forgetPassword_newpassword_format_error")));
      } else {
        if(this.form.newpassword && this.form.againnewpassword && this.form.newpassword != this.form.againnewpassword){
          callback(new Error('两次输入密码不一致'));
        }else{
          callback();
        }

      }
    };
    const validateCaptcha = (rule, value, callback) => {
      var re = /^\w{4}$/;
      if (!value) {
        return callback(new Error(this.$t('register_input_captcha_empty')));
      }
      if (!re.test(value)) {
        return callback(new Error(this.$t('register_input_captcha_format_error')));
      }
      if (!util.captchaCodeCheck(value,this.captchaHash)) {
        return callback(new Error(this.$t('register_input_captcha_error')));
      }
      callback();
    };
    const validateMobileCode = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('register_input_mobile_code')));
      }
      callback();
    };
    return {
      userAgent: navigator.userAgent,
      loading: false,
      form: {
        mobile: "",
        newpassword: "",
        captcha: '',
        mobileCode:'',
        againnewpassword:'',
      },
      lang: '',
      rules: {
        mobile: [
          { required: true, validator: validateMobile, trigger: "blur" }
        ],
        newpassword: [
          { required: true, validator: validateNewPassword, trigger: "blur" },
        ],
        againnewpassword: [
          { required: true, validator: validateNewPassword, trigger: "blur" },
        ],
        captchaCode: [
          { required: true, validator: validateCaptcha, trigger: 'blur' }
        ],
        mobileCode: [
          { required: true, validator: validateMobileCode, trigger: "blur" }
        ],

      },
      captchaUrl:'',
      captchaKey:'',
      captchaHash:'',
      mobileCodeStatus:true,
      sec:60,
      info:{},
      defaultInfo:{
        name:'数字实战评测平台'
      }
    };
  },
  computed: {
    userNameTypes() {
      const emailTxt = this.$t("register_login_email");
      const phoneNumTxt = this.$t("register_login_phone");
      const TxtArr = [];
      if (this.open_signin_email) {
        TxtArr.push(emailTxt);
      }
      if (this.open_signin_mobile) {
        TxtArr.push(phoneNumTxt);
      }
      return TxtArr;
    },
    userNameEmptyError() {
      return (
          this.$t("register_login_prefix_please") + this.userNameTypes.join("/")
      );
    },
    userNameTooLong() {
      return (
          this.userNameTypes.join("/") +
          this.$t("register_login_max_length", { count: 60 })
      );
    },
  },
  mounted() {
    this.getCaptcha();
    this.getInfo();
    this.lang = Cookies.get("lang") || "zh-CN";
  },
  methods: {
    getInfo(){
      this.api.index.indexInit().then((res)=>{

        if(res.site){
          this.info = res.site;
        }

      })
    },
    changeLang (lang) {
      this.lang = lang;
      this.$store.commit('switchLang', lang);
    },
    toLogin(){
      this.$router.push({name: 'login'});
    },
    toRegister(){
      this.$router.push({name: 'register'});
    },
    //获取图片验证码
    getCaptcha(){
      let params = {
        regenerate:1
      };
      this.api.index.getCaptcha(params).then((res)=>{
        this.captchaUrl = res.bin;
        this.captchaKey = res.key;
        this.captchaHash = res.hash;
      })
    },
    getMobileCode(){
      if(!this.form.mobile){
        this.$Message.warning(this.$t('login_mobild_user'));
        return;
      }
      if(!this.form.captcha){
        this.$Message.warning(this.$t('login_captcha_tip'));
        return;
      }
      if(!util.captchaCodeCheck(this.form.captcha,this.captchaHash)){
        this.$Message.warning(this.$t('login_captcha_tip'));
        return;
      }
      let data = {
        op:'sendMobile',
        mobile: this.form.mobile,
        verifyCode: this.form.captcha,
        verifyCodeKey:this.captchaKey,
      };
      this.api.index.forgetPassword(data).then((res)=>{
        this.mobileCodeStatus = false;
        let timer = setInterval(()=>{
          if(this.sec == 0){
            clearInterval(timer);
            this.sec = 60;
            this.mobileCodeStatus = true;
          }else{
            this.sec--;
          }
        },1000)

      })
    },
    handleSubmit() {
      this.$refs.forgetPasswordForm.validate((valid) => {
        if (valid) {
          let device_number = Cookies.get("device_number") || new Date().getTime();
          let data = {
            mobile: this.form.mobile,
            verifyCode: this.form.captcha,
            verifyCodeKey:this.captchaKey,
            mobileCode:this.form.mobileCode,
            password: util.pswEncryption(this.form.newpassword),
          };
          this.loading = true;
          this.api.index.forgetPassword(data).then((res)=>{
            this.loading = false;
            Cookies.set('liteLoginAccount', this.form.username, { expires: 7 });
            this.toLogin()
          }).catch((err)=>{
            this.loading = false;
          })
          // $.ajax({
          //   url: api.index.forgetPassword,
          //   method: "POST",
          //   data: data,
          //   success: (res) => {
          //     this.loading = false;
          //     if (res.error) {
          //       this.$Message.destroy();
          //       this.$Message.warning({
          //         content: res.message,
          //         duration: 3,
          //       });
          //     } else {
          //       Cookies.set('liteLoginAccount', this.form.username, { domain: util.getDomain(), expires: 7 });
          //       this.toLogin()
          //     }
          //   },
          //   error: (res, textStatus, responseText) => {
          //     util.handleAjaxError(this, res, textStatus, responseText, () => {
          //       this.loading = false;
          //     });
          //   },
          // });
        }
      });
    },
    toRegisterPage(){
      this.$router.push({
        name: 'register',
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.get-code{
  width: 100%;
  border: 1px solid #3399ff;
  color:#3399ff;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}
.get-code-not{
  width: 100%;
  border: 1px solid #c3cbd6;
  color:#c3cbd6;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}
</style>
